<template>
  <div>
    <header-brief v-if="ready_campaign" :campaign="campaignInfo" :disabled_update="disabled_update" @update_campaign="update"/>

    <info-campaign v-if="ready_campaign" :campaign="campaignInfo" :disabled_update="disabled_update" :role="role" :brands="brands" @update_campaign="update"/>

    <custom-fields v-if="ready_campaign" :campaign="campaignInfo" :disabled_update="disabled_update" @rerender="callAxios" :key="change_info"/>
  </div>
</template>
<script>
import headerBrief from './brief/header.vue'
import infoCampaign from './brief/infoCampaign.vue'
import customFields from './brief/customsFields.vue'

import service from "@/services/campaign";
import utils from '@/libs/utils'

export default {
  components: {
    headerBrief,
    infoCampaign,
    customFields
  },
  data () {
    return {
      disabled_update: false,
      ready_campaign: false,
      campaignInfo: {},
      brands: [],
      change_info: 0,
    }
  },
  async created () {
    const data_user = JSON.parse(localStorage.getItem('userData'));

    this.role = data_user.main_group.name;
    service.getCampaignBrief(this.$route.params.uuid)
      .then(response => {
        this.ready_campaign = true
        this.campaignInfo = response ? response : {}
      })
      .catch(() => {
      })

    service.getCampaignBrands()
      .then(response => {
        this.brands = this.getBrand(response.results)
      })
      .catch(() => {
      })

    this.disabled_update = this.disabledUpdateCampaign();
  },
  async mounted() {
    await import('quill/dist/quill.core.css');
    await import('quill/dist/quill.snow.css');
    await import('quill/dist/quill.bubble.css');
  },
  methods:{
    getBrand (info) {
      const result = []
      info.forEach(i => {
        const obj = {
          title: i.name,
          uuid: i.uuid
        }
        result.push(obj)
      })
      return result
    },
    callAxios () {
      service.getCampaign(this.$route.params.uuid)
        .then(response => {
          this.campaignInfo = response ? response : {}
        })
        .catch(() => {
        })
      service.getCampaignBrands()
        .then(response => {
          this.brands = this.getBrand(response.results)
        })

      this.change_info += 1
    },
    disabledUpdateCampaign() {
      return !utils.hasPermission('change_campaign')
    },
    update(formdata) {
      service.updateCampaign(this.$route.params.uuid, formdata)
        .then(response => {
          if (response.uuid) {
            this.$swal({
              title: this.$t('campaigns.changeSuccess'),
              text: this.$t('campaigns.changeSuccess'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false,
              heightAuto: false
            })
            this.callAxios()
          } else {
            const messages = Object.entries(response.data.response.message)
            let message = ''

            messages.forEach(error => {
              error[1].forEach(m => {
                message += m
              })
            })
            this.$swal({
              title:  this.$t('campaigns.Error'),
              text: message,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false,
              heightAuto: false
            })
          }
        })
        .catch(() => {
        })
    }
  },
}
</script>

<style>
.height-cover .card-img-top{
  max-height: 200px !important;
  object-fit: cover;
}
.height-cover .card-profile .card-body .profile-image-wrapper .profile-image img{
  height: 150px !important;
  width: 150px !important;
}
.height-cover .card-profile .card-body{
  padding: 9rem 2.3rem 1.5rem 2.3rem;
}

.changeTitle{
  position:absolute;
  top: -5px;
  right: -50px;
  cursor: pointer;
}
.plusGrand{
  font-size: 16px;
}
.back-campaign-button {
  position: absolute; 
  top: -50px; 
  left: 10px;
}
.text-brandme {
  color: #7046B6;
}
.btn-edit-cover {
  position: absolute;
  top: -50px;
  right: 10px;
}
.btn-edit-logo {
  position: absolute;
  bottom: 0px;
  right: 0;
  z-index: 9;
  background: white;
  border-radius: 50%;
  padding: 5px;
}
.w-p-48 {
  width: 48%;
}
.h-150 {
  height: 150px;
}
</style>
