<template>
  <div>
    <b-row>
      <b-col class="height-cover">
        <b-card :img-src="campaign.cover" img-top class="card-profile height-cover position-relative">
          <div class="btn-edit-cover">
            <b-button
              variant="primary"
              :disabled="disabled_update"
              @click="openFile('cover')"
            >
              {{$t('campaigns.editCover')}}
            </b-button>
          </div>
          <div class="back-campaign-button">
            <b-button variant="primary" @click="$router.push({ name: checkOutHome() ? 'realty-campaign' : 'campaign', params: { id: $route.params.uuid }})">
              {{$t('campaigndetail')}}
            </b-button>
          </div>
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <div class="btn-edit-logo">
                <b-button
                  variant="outline-primary"
                  class="btn-icon rounded-circle"
                  :disabled="disabled_update"
                  @click="openFile('logo')"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
              </div>
              <b-avatar
                size="164"
                variant="light"
                :src="campaign.logo"
              />
            </div>
          </div>
          <div class="position-relative d-inline-block">
            <h1>{{ campaign.name }}
              <b-button
                variant="flat-primary"
                class="btn-icon rounded-circle"
                :disabled="disabled_update"
                @click="showEditName"
              >
                <feather-icon icon="EditIcon" />
              </b-button>
            </h1>
            <b-link 
              class="text-brandme" 
              @click="$router.push({name: 'requirements-netowrks', params: {uuid: $route.params.uuid}})"
              v-if="!isOOH()"
            >
              {{$t('campaigns.open_requirements')}}
              <feather-icon
                icon="Link2Icon"
                size="12"
              />
            </b-link>
            <div>
              <hr>
              <div>
                <h3>{{$t('campaigns.Description')}}
                <b-button variant="flat-primary" class="btn-icon" @click="showEditDescription" :disabled="disabled_update">
                  <feather-icon icon="EditIcon" />
                </b-button>
                </h3>
              </div>
              <div ref="collapsable_div" class="plusGrand collapsable-div" v-html="description"></div>
              <div class="chevron-icon mb-1" v-if="more_description" :key="change_description">
                <b-tooltip target="view_filters" variant="primary" :title="!visible ? $t('instagram.seeMore') : $t('instagram.seeLess')"></b-tooltip>
                <div id="view_filters" @click="collapseFilters()">
                  <b-icon class="icon-view-filters cursor-pointer" :icon="!visible ? 'chevron-down' : 'chevron-up'" font-scale="1.5"/>
                </div>
              </div>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-form-file
      id="cover"
      ref="file_upload"
      accept=".jpeg, .jpg, .png"
      class="d-none"
      @change="hasChangedFile"
    />

    <modal-brandme :show_modal="show_modal_name" :key="change_modal_name">
      <template #title>{{$t('campaigns.changesCampaign')}}</template>
      <template #body>
        <label>{{$t('campaigns.changeCampaign')}}</label>
        <b-input-group>
          <b-form-input
            v-model="name_campaign"
            autocomplete="off"
            maxlength="100"
          />
        </b-input-group>
        <div class="d-flex justify-content-end mt-2">
          <b-button variant="outline-secondary" class="mr-1" @click="closeEditName()">{{$t('campaigns.close')}}</b-button>
          <b-button variant="primary" @click="updateName()">{{$t('dashboard.save')}}</b-button>
        </div>
      </template>
    </modal-brandme>

    <modal-brandme size="xl" :show_modal="show_modal" :key="change_modal">
      <template #title>{{$t('campaigns.descriptionEdit')}}</template>
      <template #body>
        <b-form-group class="mt-2">
          <component
            :is="quill_editor"
            v-model="description"
            :options="editorOption"
            class="border-bottom-0 mw-100 h-150"
          />
          <div id="description-quill-toolbar" class="d-flex justify-content-end border-top-0">
            <!-- Add a bold button -->
            <button class="ql-bold" />
            <button class="ql-italic" />
            <button class="ql-underline" />
            <button class="ql-align" />
            <button class="ql-link" />
          </div>
        </b-form-group>

        <div class="d-flex justify-content-end">
          <b-button variant="outline-secondary" class="mr-1" @click="closeEditDescription()">{{$t('campaigns.close')}}</b-button>
          <b-button variant="primary" @click="updateDescription()">{{$t('dashboard.save')}}</b-button>
        </div>
      </template>
    </modal-brandme>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BLink, BButton, BFormFile, BFormGroup, BInputGroup, BFormInput, BTooltip, BIcon
} from 'bootstrap-vue'
import ModalBrandme from '@/views/components/modal/ModalBrandme.vue';
import { hasPermission, isOOH } from '@/libs/utils/user';


export default {
  name: 'headerBrief',
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BLink,
    BButton,
    BFormFile,
    ModalBrandme,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BTooltip,
    BIcon
  },
  props: {
    campaign: {
      type: Object,
      default: () => {}
    },
    disabled_update: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isOOH,
      hasPermission,
      upload_type: null,
      show_modal: false,
      change_modal: false,
      show_modal_name: false,
      change_modal_name: 0,
      description: this.campaign ? this.campaign.description : '',
      name_campaign: this.campaign ? this.campaign.name : '',
      more_description: false,
      visible: false,
      change_description: false,
      quill_editor: null,
    }
  },
  setup (props, context) {
    const editorOption = {
      modules: {
        toolbar: `#description-quill-toolbar`
      },
      placeholder: context.parent.$t('campaigns.writeHere')
    }
    return {
      editorOption
    }
  },
  created() {
    setTimeout(() => {
      this.more_description = this.$refs.collapsable_div.clientWidth < this.$refs.collapsable_div.scrollWidth || 100 < this.$refs.collapsable_div.scrollHeight;
    }, 50)
  },
  async mounted() {
    const vue_quill_editor = (await import('vue-quill-editor'));
    this.quill_editor = vue_quill_editor.quillEditor;
  },
  methods: {
    collapseFilters () {
      this.visible = !this.visible
      this.$refs.collapsable_div.classList.toggle('collapsable-div')
    },
    showEditName() {
      this.show_modal_name = true
      this.change_modal_name += 1
    },
    closeEditName() {
      this.show_modal_name = false
      this.change_modal_name += 1
    },
    showEditDescription() {
      this.show_modal = true
      this.change_modal = !this.change_modal
    },
    closeEditDescription() {
      this.show_modal = false
      this.change_modal = !this.change_modal
    },
    checkOutHome() {
      return window.location.host.includes('outofhome')
    },
    openFile(upload_type) {
      this.upload_type = upload_type;
      this.$refs.file_upload.$refs['input'].click();
    },
    hasChangedFile() {
      setTimeout(() => {
        if (this.upload_type === 'logo') this.uploadLogo();
        else this.uploadCover();
      },200)
    },
    uploadCover () {
      if (this.disabledUpdateCampaign()) return;
      const cover = this.$refs.file_upload.files[0]

      const formData = new FormData()
      formData.append('cover', cover)
      this.$emit('update_campaign', formData)
    },
    uploadLogo () {
      if (this.disabledUpdateCampaign()) return;
      const logo = this.$refs.file_upload.files[0]

      const formData = new FormData()
      formData.append('logo', logo)
      this.$emit('update_campaign', formData)
    },
    updateDescription () {
      if (this.disabledUpdateCampaign()) return;
      const formData = new FormData()
      formData.append('description', this.description)
      this.$emit('update_campaign', formData)
      this.show_modal = false
      this.change_modal = !this.change_modal
      this.more_description = this.$refs.collapsable_div.clientWidth < this.$refs.collapsable_div.scrollWidth || 100 < this.$refs.collapsable_div.scrollHeight;
      this.change_description = !this.change_description
    },
    updateName() {
      if (this.disabledUpdateCampaign()) return;
      const formData = new FormData()
      formData.append('name', this.name_campaign)
      this.$emit('update_campaign', formData)
      this.show_modal_name = false
      this.change_modal_name += 1
    },
    disabledUpdateCampaign() {
      return !hasPermission('change_campaign')
    },
  },
  watch: {
    '$i18n.locale'() {
      this.editorOption.placeholder = this.$t('campaigns.writeHere')
    }
  }
}
</script>
<style>
.collapsable-div {
  height: 100px;
  overflow: hidden;
}
</style>