<template>
  <div>
    <b-row>
      <b-col v-if="(type_custom_field !== 'network' && !is_network)" class="col-12">
          <b-form-group>
            <b-form-input :placeholder="$t('campaigns.title')" v-model="custom_name"/>
          </b-form-group>
      </b-col>

      <b-col class="col-12">
        <b-form-checkbox v-model="show_link" class="custom-control-primary text-left mb-1">
          {{$t('campaigns.showMessage')}}
        </b-form-checkbox>
      </b-col>

      <b-col v-if="type_custom_field === 'both'" class="col-12">
        <b-form-checkbox v-model="is_network" class="custom-control-primary text-left mb-1">
            Es un requerimiento de red social
          </b-form-checkbox>
      </b-col>


      <b-col v-if="(type_custom_field === 'network' || is_network)" class="col-12">
        <div class="d-flex justify-content-between flex-wrap align-items-center">
          <b-form-select class="col-12 col-md-5 mb-1" v-model="network_selected" :options="networks"></b-form-select>
          <b-form-select class="col-12 col-md-5 mb-1" v-model="selected_type" :options="type_requirement"></b-form-select>
        </div>
      </b-col>
    </b-row>

    <b-form-group class="mt-2">
      <component
        :is="quill_editor"
        v-model="custom_plus"
        :options="editorOption"
        class="border-bottom-0"
        style="max-width: 100%; height: 150px"
      />
      <div id="customfields-quill-toolbar" class="d-flex justify-content-end border-top-0">
        <!-- Add a bold button -->
        <button class="ql-bold" />
        <button class="ql-italic" />
        <button class="ql-underline" />
        <button class="ql-align" />
        <button class="ql-link" />
      </div>
    </b-form-group>

    <div class="d-flex justify-content-end">
      <b-button variant="outline-secondary" class="mr-1" @click="$emit('closeModal')">{{$t('campaigns.close')}}</b-button>
      <b-button variant="primary" @click="saveRequirement()">{{$t('dashboard.save')}}</b-button>
    </div>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BButton, BFormSelect } from "bootstrap-vue";
import campaign_service from "@/services/campaign";

export default {
  name: 'modalNewRequirement',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BButton,
    BFormSelect,
  },
  props: {
    campaign_uuid: {
      type: String,
      default: ''
    },
    order: {
      type: Number,
      default: 0
    },
    editable_data: {
      type: Object,
      default: () => null
    },
    type_custom_field: {
      type: String,
      default: 'both'
    }
  },
  data() {
    return {
      custom_name: this.editable_data ? this.editable_data.front_name : '',
      show_link: this.editable_data ? this.editable_data.show_link : true,
      custom_plus: this.editable_data ? this.editable_data.form_value : '',
      is_network: this.type_custom_field === 'network' || (this.editable_data && this.editable_data.network !== null),
      network_selected: this.editable_data && this.editable_data.network !== null ? this.editable_data.network : 'instagram',
      networks: [
        { value: 'instagram', text: 'Instagram'},
        { value: 'youtube', text: 'Youtube' },
        { value: 'tiktok', text: 'Tiktok' },
        { value: 'twitter', text: 'Twitter' },
        { value: 'facebook', text: 'Facebook' },
        { value: 'linkedin', text: 'Linkedin' },
        { value: 'spotify', text: 'Spotify' },
        { value: 'pinterest', text: 'Pinterest' },
        { value: 'twitch', text: 'Twitch' },
        { value: 'blog', text: 'Blog' },
        { value: 'snapchat', text: 'Snapchat' },
      ],
      type_requirement: [
        { value: 'requirement', text: 'Requerimiento' },
        { value: 'element', text: 'Elemento' },
      ],
      selected_type: 'requirement',
      mutable_custom: this.editable_data,
      quill_editor: null,
    }
  },
  setup (props, context) {
    const editorOption = {
      modules: {
        toolbar: `#customfields-quill-toolbar`
      },
      placeholder: context.parent.$t('campaigns.writeHere')
    }
    return {
      editorOption
    }
  },
  async mounted() {
    const vue_quill_editor = (await import('vue-quill-editor'));
    this.quill_editor = vue_quill_editor.quillEditor;
    await import('quill/dist/quill.core.css');
    await import('quill/dist/quill.snow.css');
    await import('quill/dist/quill.bubble.css');
  },
  methods: {
    saveCustomField() {
      const formData = new FormData()
      formData.append('form_value', this.custom_plus)
      formData.append('show_link', this.show_link)
      formData.append('order', this.order)

      if (this.is_network) {
        formData.append('front_name', this.network_selected)
        formData.append('network', this.network_selected)
        formData.append('type_field', this.selected_type)
      } else {
        formData.append('front_name', this.custom_name)
      }

      campaign_service.createCustomField(this.campaign_uuid, formData)
        .then(response => {
          if (response.code) {
            this.$swal({
              title: 'Error',
              text: 'No se ha podido crear el requerimiento',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          } else {
            this.$swal({
              title: this.$t('campaigns.requirementAdded'),
              text: this.$t('campaigns.requirementSuccess'),
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
            this.$emit('customCreated', response.custom_fields)
          }
        })
    },
    updateCustomField() {
      const formData = new FormData()
      formData.append('form_value', this.mutable_custom.form_value)
      formData.append('show_link', this.mutable_custom.show_link)
      formData.append('order', this.mutable_custom.order)
      if (this.is_network) {
        formData.append('front_name', this.network_selected)
        formData.append('network', this.network_selected)
        formData.append('type_field', this.selected_type)
      } else {
        formData.append('front_name', this.custom_name)
      }

      campaign_service.updateCampaignField(this.$route.params.uuid, this.mutable_custom.uuid, formData)
        .then(() => {
          this.$emit('customCreated')
          this.$swal({
            title: this.$t('campaigns.modifiedField'),
            text: this.$t('campaigns.fieldSuccess'),
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })
        })
        .catch(() => {
        })
    },
    saveRequirement() {
      if (this.editable_data) {
        this.mutable_custom.form_value = this.custom_plus
        this.mutable_custom.front_name = this.is_network ? this.network_selected : this.custom_name
        this.mutable_custom.show_link = this.show_link
        this.mutable_custom.network = this.is_network ? this.network_selected : null
        this.mutable_custom.type_field = this.is_network ? this.selected_type : null
        this.updateCustomField()
      } else if (this.campaign_uuid) {
        this.saveCustomField()
      } else {
        const data = {
          form_value: this.custom_plus,
          front_name: this.is_network ? this.network_selected : this.custom_name,
          show_link: this.show_link,
          order: this.order,
          network: this.is_network ? this.network_selected : null,
          type_field: this.is_network ? this.selected_type : null
        }

        this.$emit('dataCustomField', data)
      }
    }
  },
  watch: {
    '$i18n.locale'() {
      this.editorOption.placeholder = this.$t('campaigns.writeHere')
    }
  }
};
</script>

<style>
</style>
